<template>
  <div
    class="welcome"
    data-aos="fade-up"
    data-aos-duration="200"
    data-aos-offset="200"
  >
    <div class="welcome__container col-11 col-sm-10">
      <h1>{{ data?.title }}</h1>
      <p>{{ data?.sub }}</p>
      <router-link to="/contact"
        ><button class="btn-contact mt-5">
          <span class="text-nowrap">
            {{ data.btn }}
            <img
              v-lazy="imagePath"
              alt="Vector Welcome Project"
              style="width: 17px"
            />
          </span>
        </button>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Welcome',
  data() {
    return {
      data: this.$t('about_us').welcome,
      imagePath: '/images/projects/VectorWelcome.png',
    };
  },
  watch: {
    lang() {
      this.data = this.$t('about_us').welcome;
    },
  },
  computed: {
    ...mapState({
      lang: (state) => state.langs.lang,
    }),
  },
};
</script>

<style lang="scss" scroped>
.welcome {
  width: 100%;
  padding: 80px 0 110px 0;
  .welcome__container {
    padding: 80px 100px;
    border-radius: 20px;
    margin: auto;
    text-align: center;
    background-image: url('/images/bg/bg-welcome.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 18px;
    h1 {
      font-size: 34px;
      font-weight: 800;
    }
    a {
      text-decoration: none;
    }
    .btn-contact {
      border: none;
      padding: 10px 25px;
      background: #fff;
      color: var(--orange);
      border-radius: 225px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      margin: 0 auto;
      span {
        img {
          padding-left: 5px;
        }
      }
    }

    .btn-contact:hover {
      opacity: 0.8;
      transition: 0.3s;
    }
  }
  @media (max-width: 1024px) {
    .welcome__container {
      padding: 40px 20px;
      width: 100%;
    }
  }
  @media (max-width: 768px) {
    padding: 0 0 50px 0;
    .welcome__container {
      h1 {
        font-size: 32px;
        font-weight: 700;
      }
      height: 100%;
      width: 92%;
    }
  }

  @media (max-width: 600px) {
    padding: 0 0 50px 0;
    .welcome__container {
      h1 {
        font-size: 28px;
        font-weight: 700;
      }
      height: 100%;
      width: 92%;

      .btn-contact {
        margin-bottom: 20px;
      }
    }
  }
}
</style>
